
import { Download, Features, SectionWrapper, Footer, Contact } from '../components';
import { feature, homeCards, homeHero, mockup } from '../assets';

const Home = () => {
  return (
    <>
      <SectionWrapper
        title="BizFlowSync - An Efficient Business Management Platform."
        description="It is where innovation meets productivity, empowering your organization to thrive in the dynamic business landscape."
        showBtn
        mockupImg={homeHero}
        banner="banner"
      />
      <SectionWrapper
        title="Smart Students Management Platform"
        description="It is a student management application that you can effortlessly manage student records, track performance, and streamline administrative tasks."
        mockupImg={homeCards}
        reverse
      />
      <Features />
      <SectionWrapper
        title="Fees Management"
        description="It includes features such as fee tracking, invoicing, payment reminders, and reporting to ensure smooth financial operations and transparency."
        mockupImg={feature}
        reverse
      />
      <SectionWrapper
        title="Attendance Management"
        description="It includes features such as check-in and check-out systems, automated attendance recording, real-time monitoring, and reporting functionalities."
        mockupImg={mockup}
        banner="banner02"
      />

      <Download />

      <Contact />
      
      <Footer />
    </>
  );
}

export default Home;
