import React, { Component } from 'react'
import styles from '../styles/Global';

class Footer extends Component {
    render() {
        return (
            <footer className="banner04">
                <div className="mx-auto w-full max-w-screen-xl p-4">
                    <div className="my-12">
                        <div className="flex sm:flex-col items-center justify-between -mb-6">
                            <span className=" text-white sm:text-center" style={{ fontFamily: "auto" }}>© 2024 <a href="https://bizflowsync.com/" className="hover:underline">Bizflowsync™</a> All Rights Reserved.
                            </span>

                            <div className="flex sm:justify-center sm:mt-3">
                                <p className={`${styles.pText} ${styles.whiteText} my-0`} style={{ fontFamily: "auto", fontSize: "inherit" }}>Made with love by {" "}
                                    <span className="bold">Rahul Rai ❤️ </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;