import React, { Component } from 'react';
import { logo } from '../assets';
import { Link } from "react-router-dom"
import { FiChevronsRight } from 'react-icons/fi';
import "./policy.css"

class ShippingAndDelivery extends Component {
    render() {
        return (
            <>
                <div className='w-4/6 sm:w-5/6 px-3 mx-auto'>
                    <Link to="/">
                        <img src={logo} alt='logo' className='py-3 h-20' />
                    </Link>
                </div>
                <hr />
                <div className='w-4/6 sm:w-5/6 px-3 mx-auto privacy-policy'>
                    <div className='flex items-center py-3'>
                        <h4 style={{ color: "#59b2ab" }}>
                            <Link to="/">
                                Home
                            </Link>
                        </h4>
                        <FiChevronsRight />
                        Shipping & Delivery Policy
                    </div>

                    <div className='flex justify-center py-3'>
                        <div className='w-full'>
                            <h2 className='text-center py-8'>Shipping & Delivery Policy</h2>
                            <p className='my-3'><strong>Last updated</strong>: September 26, 2024</p>
                            <p>Please carefully review our Shipping & Delivery Policy when purchasing our products. This policy will apply to any order you place with us.</p>
                            <br />
                            <h3>WHAT ARE MY SHIPPING & DELIVERY OPTIONS?</h3>
                            <p>We offer various shipping options. In some cases a third-party supplier may be managing our inventory and will be responsible for shipping your products.</p>
                            <br />
                            <h3>Free Shipping</h3>
                            <p>We offer free Orders Over 200INR  shipping on Over 200 INR Premium Subscribers</p>
                            <br />
                            <h3>Shipping Fees</h3>
                            <div>
                                <div style={{ lineHeight: 1.5 }}>
                                    <p>We also offer shipping at the following rates:</p>
                                </div>
                                <div style={{ lineHeight: 1.5 }}>
                                    <br />
                                </div>
                                <table style={{ width: "44%", marginRight: "calc(56%)" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "57.8771%", borderbottom: "1px solid black" }}>
                                                <div style={{ textAlign: "center", lineHeight: 1 }}>
                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                            <span data-custom-class="body_text">
                                                                <strong>Shipping Method</strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ textAlign: "center", lineHeight: 1 }}>
                                                    <br />
                                                </div>
                                            </td>
                                            <td style={{ width: "42.4261%", borderBottom: "1px solid black" }}>
                                                <div style={{ textAlign: "center", lineHeight: 1 }}>
                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                        <span style={{ color: " rgb(89, 89, 89)", fontSize: "15px" }}>
                                                            <span data-custom-class="body_text">
                                                                <strong>Shipping Fee</strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ textAlign: "center", lineHeight: 1 }}>
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <table style={{ width: "44%", marginRight: "calc(56%)" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "57.8771%" }}>
                                                <div style={{ textAlign: "center", lineHeight: 1 }}>
                                                    <br />
                                                </div>
                                                <div style={{ textAlign: "center", lineHeight: 1 }}>
                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="question">
                                                                    Ground shipping
                                                                </bdt>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ textAlign: "center", lineHeight: 1.4 }}>
                                                    <span style={{ color: "rgb(127, 127, 127)" }}>
                                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                            <span data-custom-class="body_text">
                                                                <bdt className="block-component">
                                                                </bdt>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td style={{ width: "42.4261%", textAlign: "center", lineHeight: 1 }}>
                                                <br />
                                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                                                        <span data-custom-class="body_text">
                                                            <bdt className="question">1</bdt>
                                                        </span>
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <span>If you select a shipping option, we will follow up after you have placed the order with any additional shipping information.</span>
                                <br />
                                <span>All times and dates given for delivery of the products are given in good faith but are estimates only.</span>
                                <br />
                                <br />
                                <h3>DO YOU DELIVER INTERNATIONALLY?</h3>
                                <span>We do not offer international shipping.</span>
                                <br />
                                <br />
                                <h3>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
                                <span>If you have any further questions or comments, you may contact us by:</span>
                                <ul>
                                    <li><strong>Email: </strong>bizflowsync@gmail.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default ShippingAndDelivery;