// validate email
export const emailVerify = (errors = {}, values) => {
    if (!values.email) {
        errors.email = "This field is required!";
    } else if (values.email.includes(" ")) {
        errors.email = "Wrong Email...!";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address...!";
    }

    return errors;
};


// validate email
export const mobileVerify = (errors = {}, values) => {
    const regexPattern = /^(\+91[\-\s]?)?[6789]\d{2}[\-\s]?\d{3}[\-\s]?\d{4}$/;

    if (!values.mobile) {
        errors.mobile = "This field is required!";
    } else if (!regexPattern.test(values.mobile)) {
        errors.mobile = "Mobile isn't valid!";
    }

    return errors;
};

export const createContactValidate = (values) => {
    let errors = emailVerify({}, values);

    mobileVerify(errors, values);

    if (!values?.name?.trim()) {
        errors.name = "This field is required!"
    }

    if (!values?.organization?.trim()) {
        errors.organization = "This field is required!"
    }

    if (!values?.description?.trim()) {
        errors.description = "This field is required!"
    }

    if (!values?.address?.trim()) {
        errors.address = "This field is required!"
    }


    return errors;
}