import React, { Component } from 'react'
import {
    BsInstagram,
    BsLinkedin
} from 'react-icons/bs';
import { createContactValidate } from '../validate';
import { Formik } from 'formik';
import axiosInstance from '../api/base';
import Spinner from './Spinner';
import toast from 'react-hot-toast';


class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: null
        }
    }

    render() {
        return (
            <div className="mt-6 mb-20">
                <div className="grid grid-cols-2 sm:grid-cols-1 items-start gap-14 p-8 mx-auto max-w-6xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md font-[sans-serif]">
                    <div>
                        <h1 className="text-gray-800 text-3xl font-extrabold" style={{ fontFamily: "auto" }}>Let's Connect</h1>
                        <p className="text-sm text-gray-500 mt-4" style={{ fontFamily: "auto" }}>
                            Got questions or need expert solutions for managing your business, studentas, attendance, Fees? Drop us a message, and we'll get back to you with the perfect fix, easy, and tailored just for you!
                        </p>

                        <div className="mt-12">
                            <h2 className="text-gray-800 text-base font-bold" style={{ fontFamily: "auto" }}>Email</h2>
                            <ul className="mt-6" style={{ padding: "unset !importent" }}>
                                <li className="flex items-center">
                                    <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#6b7280'
                                            viewBox="0 0 479.058 479.058">
                                            <path
                                                d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                                                data-original="#000000" />
                                        </svg>
                                    </div>
                                    <a href="#" className=" text-gray-500 text-sm ml-4">
                                        <span className="block">Mail</span>
                                        <strong>bizflowsync@gmail.com</strong>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="mt-12">
                            <h2 className="text-gray-800 text-base font-bold" style={{ fontFamily: "auto" }}>Socials</h2>

                            <ul className="flex mt-6 space-x-4 sm:space-x-1">
                                <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <a href="https://facebook.com/bizflowsync">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill='#6b7280'
                                            viewBox="0 0 24 24">
                                            <path
                                                d="M6.812 13.937H9.33v9.312c0 .414.335.75.75.75l4.007.001a.75.75 0 0 0 .75-.75v-9.312h2.387a.75.75 0 0 0 .744-.657l.498-4a.75.75 0 0 0-.744-.843h-2.885c.113-2.471-.435-3.202 1.172-3.202 1.088-.13 2.804.421 2.804-.75V.909a.75.75 0 0 0-.648-.743A26.926 26.926 0 0 0 15.071 0c-7.01 0-5.567 7.772-5.74 8.437H6.812a.75.75 0 0 0-.75.75v4c0 .414.336.75.75.75zm.75-3.999h2.518a.75.75 0 0 0 .75-.75V6.037c0-2.883 1.545-4.536 4.24-4.536.878 0 1.686.043 2.242.087v2.149c-.402.205-3.976-.884-3.976 2.697v2.755c0 .414.336.75.75.75h2.786l-.312 2.5h-2.474a.75.75 0 0 0-.75.75V22.5h-2.505v-9.312a.75.75 0 0 0-.75-.75H7.562z"
                                                data-original="#000000" />
                                        </svg>
                                    </a>
                                </li>
                                <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <a href="https://www.instagram.com/bizflowsync">
                                        <BsInstagram color={"#6b7280"} />
                                        <span className="sr-only">Instagram</span>
                                    </a>
                                </li>
                                <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <a href="https://x.com/bizflowsync">
                                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6b7280" viewBox="0 0 20 17">
                                            <path fillRule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clipRule="evenodd" />
                                        </svg>
                                        <span className="sr-only">Twitter</span>
                                    </a>
                                </li>
                                <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <a href="https://www.linkedin.com/company/bizflowsync">
                                        <BsLinkedin color="#6b7280" />
                                        <span className="sr-only">LinkedIn</span>
                                    </a>
                                </li>
                                <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <a href="https://github.com/bizflowsync">
                                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6b7280" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z" clipRule="evenodd" />
                                        </svg>
                                        <span className="sr-only">GitHub account</span>
                                    </a>
                                </li>
                                <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <a href="https://dribbble.com/bizflowsync">
                                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6b7280" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z" clipRule="evenodd" />
                                        </svg>
                                        <span className="sr-only">Dribbble account</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            name: "",
                            mobile: "",
                            email: "",
                            organization: "",
                            description: "",
                            address: ""
                        }}
                        resetForm={true}
                        validate={createContactValidate}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={async (values, { resetForm }) => {
                            this.setState({ loading: true })

                            await axiosInstance.post(`/contact/`, values)
                                .then((response) => {
                                    this.setState({ loading: false }, () => {
                                        resetForm()
                                        toast.success("Your Query Submitted Successfuly! We will connect you soon.")
                                    })
                                })
                                .catch((error) => {
                                    this.setState({ loading: false }, () => {
                                        toast.success("Your Query can't be submitted!")
                                    })
                                })
                        }}
                    >
                        {
                            (props) => (
                                <form className="ml-auo space-y-4">
                                    <h3 className="text-gray-800 text-3xl font-extrabold" style={{ fontFamily: "auto" }}>Get a free demo now</h3>
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        className="w-full text-gray-800 rounded-md py-2.5 px-4 border text-sm outline-blue-500"
                                        value={props.values.name}
                                        onChange={(e) => props.setFieldValue("name", e.target.value)}
                                    />

                                    <span className="text-red-500" style={{ float: "right", marginTop: "0px" }}>{props.errors.name}</span>

                                    <input
                                        type='text'
                                        placeholder='Mobile'
                                        className="w-full text-gray-800 rounded-md py-2.5 px-4 border text-sm outline-blue-500"
                                        value={props.values.mobile}
                                        onChange={(e) => props.setFieldValue("mobile", e.target.value)}
                                    />

                                    <span className="text-red-500" style={{ float: "right", marginTop: "0px" }}>{props.errors.mobile}</span>

                                    <input
                                        type='email'
                                        placeholder='Email'
                                        className="w-full text-gray-800 rounded-md py-2.5 px-4 border text-sm outline-blue-500"
                                        value={props.values.email}
                                        onChange={(e) => props.setFieldValue("email", e.target.value)}
                                    />

                                    <span className="text-red-500" style={{ float: "right", marginTop: "0px" }}>{props.errors.email}</span>

                                    <input
                                        type='text'
                                        placeholder='Institute/Coaching/Gym/Library'
                                        className="w-full text-gray-800 rounded-md py-2.5 px-4 border text-sm outline-blue-500"
                                        value={props.values.organization}
                                        onChange={(e) => props.setFieldValue("organization", e.target.value)}
                                    />

                                    <span className="text-red-500" style={{ float: "right", marginTop: "0px" }}>{props.errors.organization}</span>

                                    <input
                                        type='text'
                                        placeholder='City, State'
                                        className="w-full text-gray-800 rounded-md py-2.5 px-4 border text-sm outline-blue-500"
                                        value={props.values.address}
                                        onChange={(e) => props.setFieldValue("address", e.target.value)}
                                    />

                                    <span className="text-red-500" style={{ float: "right", marginTop: "0px" }}>{props.errors.address}</span>

                                    <textarea
                                        placeholder='Message'
                                        rows="6"
                                        className="w-full text-gray-800 rounded-md px-4 border text-sm pt-2.5 outline-blue-500"
                                        value={props.values.description}
                                        onChange={(e) => props.setFieldValue("description", e.target.value)}
                                    ></textarea>
                                    <span className="text-red-500" style={{ float: "right", marginTop: "0px" }}>{props.errors.description}</span>

                                    <button
                                        type='button'
                                        className="text-white flex justify-center gap-1 bg-primary hover:bg-blue-600 rounded-md text-sm px-4 py-3 w-full !mt-6"
                                        style={{ fontFamily: "auto" }}
                                        onClick={props.handleSubmit}
                                    >
                                        {this.state.loading ? <Spinner /> : null}&nbsp;Submit
                                    </button>
                                </form>
                            )}
                    </Formik>
                </div>
            </div>
        )
    }
}

export default Contact;
